* {
  min-width: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0;
  color: #65605E;
  font-family: 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ccc;
}

@media not print and (prefers-color-scheme: dark) {
  body {
    background: #121212;
  }
}

@media not print {
  body {
    text-shadow: 1px 1px 1px rgba(0,0,0,0.03);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

html, body, #root {
  height: 100%;
}

@page {
  size: auto;
  margin: 0;
}

@media print {
  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    background: none;
  }

  max-ai-minimum-app, use-chat-gpt-ai-content-menu, use-chat-gpt-ai {
    display: none !important;
  }
}

/* GLOBAL CLASSES */
.uppercase {
  text-transform: uppercase;
}